import firebase from "firebase";
import APIResponse from "../model/APIResponse";
import {ApiInterface} from "./dataInterfaceFactory";
import {Activity, Invitation, OrganizationUser, Role, User} from "myfitworld-model";
import {firestore} from "../firebase";
import {sanitizeInput} from "./sanitizeInput";
import {UserProgram} from "myfitworld-model/dist/firestoreCollectionTypes/User";
import {MealsInDay, UserMessagePreferences, UserNutritionPlan, WorkoutDay} from "myfitworld-model/src/firestoreCollectionTypes/User";
import UserWithCRUDInstructions, {
  MealsInDayDeletion,
  NutritionPlanDeletion,
  ProgramDeletion,
  UserInvitationWithCrudInstructions,
  WorkoutDeletion
} from "./UserWithCRUDInstructions";
import invitationsApi from "./invitationsApi";
import makeArrayContainsAnyArgumentForUserList from "../utils/makeArrayContainsAnyArgumentForUserList";
import { isOrganizationOnTheFreePlanAndHasTwoClients } from "./billingDetailsApi";
import { validateEmail } from "../validators/validateEmail";
import { checkSuperCardNum } from "../validators/validateLoyalty";
import { getIdForSingleWorkoutsProgram } from "./assignedProgramsApi";

export const FUNCTIONS_BASE_URL = process.env.REACT_APP_FUNCTIONS_BASE_URL;

export const listUsers = (roles: Role[], orgId?: string) => (): Promise<User[]> => {
  return new Promise((resolve) => {
    let ref = firestore.collection("users");

    return (orgId ? ref.where(
      "organizations",
      "array-contains-any",
      makeArrayContainsAnyArgumentForUserList(roles, orgId)
    ) : ref)
      .get()
      .then((querySnapshot) => {
        const data: Array<User> = [];
        querySnapshot.forEach((snapshot) => {
          data.push({
            id: snapshot.id,
            ...snapshot.data(),
          } as User);
        });
        resolve(data);
      })
      .catch((error) => {
        resolve([]);
      });
  });
};

export const getUser = async (id: string) => {
  try {
    const userDoc = await firestore.collection("users").doc(id).get();
    
    if (!userDoc.exists) {
      throw new Error(`users/${id} not found!`);
    }

    const user = { id: userDoc.id, ...userDoc.data() } as User;

    const assignedNutritionPlansSnapshot = await firestore.collection(`users/${userDoc.id}/assignedNutritionPlans`).get();
    user.nutrition_plans = assignedNutritionPlansSnapshot.docs.map((p) => ({ id: p.id, ...p.data() } as UserNutritionPlan));
    
    const nutritionPlanPromises = user.nutrition_plans.map(async (plan) => {
      const assignedMealsInDaysSnapshot = await firestore.collection(`users/${userDoc.id}/assignedNutritionPlans/${plan.id}/assignedMealsInDays`).get();
      plan.meals = assignedMealsInDaysSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
          date: data.dateTime && data.dateTime.toDate ? data.dateTime.toDate().toJSON() : new Date(data.dateTime).toJSON(),
        } as MealsInDay;
      });
    });

    await Promise.all([ ...nutritionPlanPromises]);

    return user;
  } catch (error) {
    return undefined;
  }
};

export const getOrganizationUser = async (userId: string, orgId: string) =>
  new Promise((resolve, reject) => {
    firestore
      .collection("organizationUser")
      .doc(`${userId}-${orgId}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const user = {id: doc.id, ...doc.data()} as OrganizationUser;
          resolve(user);
        } else {
          reject(`User ${userId}-${orgId} not found!`);
        }
      })
      .catch((error) => {
        resolve(undefined);
      });
  });

export const getNumberOfAdminsTrainers = async (organizationId: string, role: Role) => {
  try {
    const querySnapshot = await firestore
      .collection("organizationUser")
      .where("orgId", "==", organizationId || "")
      .where("role", "==", role)
      .where("archived", "==", false)
      .get();

    let data = [];
    querySnapshot.forEach((snapshot) => {
      data.push({id: snapshot.id, ...snapshot.data()} as User);
    });

    return data.length;
  } catch (err) {
    throw err;
  }
};

export const updateUserOrganizations = async (data: any) =>{
  try{
    const userId:string = data.id;
    delete data.id;
    await  firestore
    .collection("users")
    .doc(userId)
    .update(data)

  } catch(error){
    throw error;
  } 
}
export const updateUserMessagePreferences = async (userId: string, messagePreferences: UserMessagePreferences) =>{
  try{
    await  firestore
    .collection("users")
    .doc(userId)
    .update({messagePreferences})
  } catch(error){
    throw error;
  } 
}

export const getCorporateOrganizationUsers = async (corpId: string) =>
  new Promise((resolve, reject) => {
    firestore
      .collection("users")
      .where("corpId", "==", corpId)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const corpUsers = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}) as User);
          resolve(corpUsers);
        } else {
          reject(`User ${corpId} not found!`);
        }
      })
      .catch((error) => {
        resolve(undefined);
      });
  });

export const updateCorpOrganizations = async (docId: string | undefined, data: any) =>
  new Promise((resolve, reject) => {
    firestore
      .collection("corporateOrg")
      .doc(docId)
      .update(data)
      .then((doc) => {
        resolve("success");
      })
      .catch((error) => {
        resolve(undefined);
      });
  });

export const createCorpOrganizations = async (data: any) =>
  new Promise((resolve, reject) => {
    firestore
      .collection("corporateOrg")
      .add(data)
      .then((doc: any) => {
        doc.update({ corpId: doc.id })
        resolve("success");
      })
      .catch((error: any) => {
        resolve(undefined);
      });
  });

export const createUser = (raw: User, errorSavingDataMessageText: string): Promise<APIResponse> => {
  const {id, ...data} = raw;
  return new Promise((resolve, reject) => {
    try {
      firestore
        .collection("users")
        .add({
          ...sanitizeInput(data),
        })
        .then((_) => {
          resolve({success: true});
        });
    } catch (e) {
      reject({
        success: false,
        errorMessage: errorSavingDataMessageText,
      });
    }
  });
};
//ASSIGNED WORKOUTS AND PROGRAMS:
const persistUserProgramWorkouts = (
  workoutRaw: WorkoutDay,
  userId: string,
  programId: string,
  operation: "UPDATE" | "INSERT",
  path: 'users' | 'invitations',
  errorSavingDataMessageText?: string,
  originalProgramId?: string
) => {
  const dateTime = workoutRaw.dateTime;
  const workout = sanitizeInput({...workoutRaw});
  // @ts-ignore
  workout.dateTime = firebase.firestore.Timestamp.fromDate(new Date(dateTime));
  
  workout.parentId = programId;
  return new Promise((resolve, reject) => {
    try {
      const ref = firestore.collection(`/${path}/${userId}/assignedPrograms/${programId}/assignedWorkouts`);
      if (operation === "UPDATE") {
        ref
          .doc(workout.id)
          .set({...workout, userId, finished: !!workout.finished})
          .then(() => {
            resolve(true);
          });
      } else {
        ref.add({...workout, userId, finished: !!workout.finished}).then(() => {
          resolve(true);
        });
      }
    } catch (e) {
      reject({
        success: false,
        errorMessage: errorSavingDataMessageText,
      });
    }
  });
};

const processUserProgramWorkouts = (workouts: WorkoutDay[], userId: string, programId: string, path: 'users' | 'invitations', errorSavingDataMessageText?: string, originalProgramId?:string) => {
  const updates = workouts.filter((w) => w.id !== undefined);
  const inserts = workouts.filter((w) => w.id === undefined);
  return [
    ...updates.map((w) => persistUserProgramWorkouts(w, userId, programId, "UPDATE", path, errorSavingDataMessageText, originalProgramId)),
    ...inserts.map((w) => persistUserProgramWorkouts(w, userId, programId, "INSERT", path, errorSavingDataMessageText, originalProgramId)),
  ];
};

const persistUserProgram = async (program: UserProgram, userId: string, operation: "UPDATE" | "INSERT", path: 'users' | 'invitations', errorSavingDataMessageText?: string) => {
  const workouts = program.workouts || [];
  delete program.workouts;
  return new Promise(async (resolve, reject) => {
    try {
      const ref = firestore.collection(`/${path}/${userId}/assignedPrograms`);
      if (operation === "UPDATE") {
        ref
          .doc(program.id)
          .set({...sanitizeInput(program)})
          .then(() => {
            if (workouts.length > 0) {
              Promise.all(processUserProgramWorkouts(workouts, userId, program.id as string,path, errorSavingDataMessageText, program.originalProgramId)).then(() => {
                resolve({success: true});
              });
            } else {
              resolve(true);
            }
          });
      } else {
        const ref = firestore.collection(`/${path}/${userId}/assignedPrograms`);
        const newProgramDocument = await ref.add({ ...sanitizeInput(program) })
        const programId = newProgramDocument.id;
        await ref.doc(programId).update({id: programId});

        if (workouts.length > 0) {
          Promise.all(processUserProgramWorkouts(workouts, userId, programId, path, errorSavingDataMessageText, program.originalProgramId))
            .then(() => {
              resolve({success: true});
            });
        } else {
          resolve(true);
        }
      }
    } catch (e) {
      reject({
        success: false,
        errorMessage: errorSavingDataMessageText,
      });
    }
  });
};

export const processUserWorkouts = (workouts: WorkoutDay[], userId: string, errorSavingDataMessageText: string) => {
  const updates = workouts.filter((w) => w.id !== undefined);
  const inserts = workouts.filter((w) => w.id === undefined && w.isSingleWorkout);
  return [
    ...updates.map((u) => persistSingleWorkout(u, userId, "UPDATE", errorSavingDataMessageText)),
    ...inserts.map((u) => persistSingleWorkout(u, userId, "INSERT", errorSavingDataMessageText)),
  ];
};

export const processTrainerNotifications = (trainerNotifications: Activity[], deletedTrainerNotifications: string[], errorSavingDataMessageText: string) => {
  const updates = trainerNotifications.filter((notification) => notification.id !== undefined);
  const inserts = trainerNotifications.filter((notification) => notification.id === undefined);
  
  return [
    ...updates.map((update) => writeActivity(update, "UPDATE", errorSavingDataMessageText)),
    ...inserts.map((insert) => writeActivity(insert, "INSERT", errorSavingDataMessageText)),
    ...deleteTrainerNotificationsDocs(deletedTrainerNotifications)
  ];
};

const deleteTrainerNotificationsDocs = (deletedTrainerNotifications: string[]) => {
  const promises: Promise<void>[] = [];  
  deletedTrainerNotifications.forEach((notificationDocId) => {
    const ref = firestore.collection("activity");
    promises.push(ref.doc(notificationDocId).delete());
  });

  return promises;
};

const persistSingleWorkout = async (workout: WorkoutDay, userId: string, operation: "UPDATE" | "INSERT", errorSavingDataMessageText: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const programId = workout.isSingleWorkout ? 
        await getIdForSingleWorkoutsProgram(userId, "users") : 
        workout.parentId;
      const ref = firestore.collection(`users/${userId}/assignedPrograms/${programId}/assignedWorkouts`);
      if (!programId) {
        return resolve(true);
      }
      if (operation === "UPDATE") {
        ref
          .doc(workout.id)
          .set({...sanitizeInput(workout)})
          .then((doc) => {
            resolve(true);
          });
      } else {
        delete workout.id;
        delete workout.isNotSaved;
        ref
        .add(
          {...workout,
          parentId: programId,
          userId: userId,
          isSingleWorkout: true,
          }
        )
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
        })
        ;
      }
    }
    catch (e) {
      reject({
        success: false,
        errorMessage: errorSavingDataMessageText,
      });
    }
  });
}

const writeActivity = async (notification: Activity, operation: "UPDATE" | "INSERT", errorSavingDataMessageText: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const ref = firestore.collection("activity");
      if(operation === "UPDATE"){
        const dateTime = notification.createdAt;
        const notificationSanitize = sanitizeInput({...notification});
        // @ts-ignore
        notificationSanitize.createdAt = firebase.firestore.Timestamp.fromDate(new Date(dateTime));
        await ref
        .doc(notification.id)
        .set({...notificationSanitize})
        .then((doc) => {
          resolve(true);
        })
        .catch((error) => {
        });
      } else {
        delete notification.id;
        await ref.add(notification)
        .then((doc) => {
          resolve(true);
        })
        .catch((error) => {
        });
      } 
      }
    catch (e) {
      reject({
        success: false,
        errorMessage: errorSavingDataMessageText,
      });
    }
  });
}

const deleteProgramDocuments = (userId: string, programDeletions: ProgramDeletion[], path: 'users' | 'invitations') => {
  const promises: Promise<void>[] = [];
  programDeletions.forEach((program) => {
    const ref = firestore.collection(`${path}/${userId}/assignedPrograms/${program.programId}/assignedWorkouts`);
    promises.push(ref.get().then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        promises.push(doc.ref.delete());
      });
    }));
    promises.push(firestore.doc(`${path}/${userId}/assignedPrograms/${program.programId}`).delete());
  });

  return promises;
}

const deleteNutritionPlanDocuments = (userId: string, nutritionPlanDeletions: NutritionPlanDeletion[], path: 'users' | 'invitations') => {
  
  const promises: Promise<void>[] = [];

  nutritionPlanDeletions.forEach((plan) => {
    const ref = firestore.collection(`${path}/${userId}/assignedNutritionPlans/${plan.nutritionPlanId}/assignedMealsInDays`);
    promises.push(ref.get().then((snapshot) => {
      
      snapshot.docs.forEach((doc) => {
        promises.push(doc.ref.delete());
      });
    }));
    promises.push(firestore.doc(`${path}/${userId}/assignedNutritionPlans/${plan.nutritionPlanId}`).delete());
  });

  return promises;
}
const deleteWorkoutDocuments = (userId: string, workoutDeletions: WorkoutDeletion[], path: 'users' | 'invitations') => {
  const promises: Promise<void>[] = [];  
  workoutDeletions.forEach((op) => {
    const ref = firestore.collection(`${path}/${userId}/assignedPrograms/${op.programId}/assignedWorkouts`);
    op.workoutIds.forEach((id) => {
      if (id !== undefined) {
        promises.push(ref.doc(id).delete());
      }
    });
  });

  return promises;
};

const deleteMealsInDayDocuments = (userId: string, mealsInDayDeletions: MealsInDayDeletion[], path: 'users' | 'invitations') => {
  
  const promises: Promise<void>[] = [];
  mealsInDayDeletions.forEach((op) => {
    const ref = firestore.collection(`${path}/${userId}/assignedNutritionPlans/${op.nutritionPlanId}/assignedMealsInDays`);
    op.mealsInDayIds.forEach((id) => {
      promises.push(ref.doc(id).delete());
    });
  });

  return promises;
};

export const processUserPrograms = (programs: UserProgram[], userId: string, workoutDeletions: WorkoutDeletion[],programDeletions:ProgramDeletion[], path: 'users' | 'invitations', errorSavingDataMessageText?: string) => {
  const updates = programs.filter((p) => p.id !== undefined);
  const inserts = programs.filter((p) => p.id === undefined);
  
  return [
    ...updates.map((u) => persistUserProgram(u, userId, "UPDATE", path, errorSavingDataMessageText)),
    ...inserts.map((u) => persistUserProgram(u, userId, "INSERT", path, errorSavingDataMessageText)),
    ...deleteWorkoutDocuments(userId, workoutDeletions, path),
    ...deleteProgramDocuments(userId, programDeletions, path)
  ];
};
function removeUndefinedFields(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(removeUndefinedFields);
  }

  const cleanedObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
      cleanedObj[key] = removeUndefinedFields(obj[key]);
    }
  }

  return cleanedObj;
}
//ASSIGNED NUTRITIONPLANS AND MEALSINDAYS:
const persistUserMealsInDays = (
  mealsInDayRaw: MealsInDay,
  userId: string,
  nutritionPlanId: string,
  operation: "UPDATE" | "INSERT",
  path: 'users' | 'invitations', 
  errorSavingDataMessageText: string
) => {
  const dateTime = mealsInDayRaw.date;
  let mealsInDay = sanitizeInput({...mealsInDayRaw});
  // @ts-ignore
  mealsInDay.dateTime = firebase.firestore.Timestamp.fromDate(new Date(dateTime));
  
  if(mealsInDay.meals){
    mealsInDay.meals = mealsInDay.meals.map(meal => {
      return {
        ...meal,
        meal: removeUndefinedFields(meal.meal)
      };
    });
  }

  // @ts-ignore
  
  return new Promise((resolve, reject) => {
    try {
      const ref = firestore.collection(`/${path}/${userId}/assignedNutritionPlans/${nutritionPlanId}/assignedMealsInDays`);
      if (operation === "UPDATE") {
        ref
          .doc(mealsInDay.id)
          .set({...mealsInDay, userId})
          .then(() => {
            resolve(true);
          })
      } else {
        ref.add({...mealsInDay, userId}).then((newDocument) => {          
          ref.doc(newDocument.id).update({id:newDocument.id})
          resolve(true);
        });
      }
    } catch (e) {
      reject({
        success: false,
        errorMessage: errorSavingDataMessageText,
      });
    }
  });
};
const processUserMealsInDays = (mealsInDays: MealsInDay[], userId: string, nutritionPlanId: string, path: 'users' | 'invitations', errorSavingDataMessageText: string) => {
  const updates = mealsInDays.filter((w) => w.id !== undefined);
  const inserts = mealsInDays.filter((w) => w.id === undefined);
  return [
    ...updates.map((w) => persistUserMealsInDays(w, userId, nutritionPlanId, "UPDATE", path, errorSavingDataMessageText)),
    ...inserts.map((w) => persistUserMealsInDays(w, userId, nutritionPlanId, "INSERT", path, errorSavingDataMessageText)),
  ];
};

const persistUserNutritionPlan = async (nutritionPlan: UserNutritionPlan, userId: string, operation: "UPDATE" | "INSERT", path: 'users' | 'invitations', errorSavingDataMessageText: string) => {
  const mealsInDays = nutritionPlan.meals || [];
  delete nutritionPlan.meals;

  return new Promise(async (resolve, reject) => {
    try {
      const ref = firestore.collection(`/${path}/${userId}/assignedNutritionPlans`);
      if (operation === "UPDATE") {
        ref
          .doc(nutritionPlan.id)
          .set({...sanitizeInput(nutritionPlan)})
          .then(() => {
            if (mealsInDays.length > 0) {
              Promise.all(processUserMealsInDays(mealsInDays, userId, nutritionPlan.id as string, path, errorSavingDataMessageText)).then(() => {
                resolve({success: true});
              });
            } else {
              resolve(true);
            }
          });
      } else {
          ref.add({...sanitizeInput(nutritionPlan)}).then((newProgramDocument) => {
            ref.doc(newProgramDocument.id).update({id:newProgramDocument.id}); 
            if (mealsInDays.length > 0) {
              Promise.all(processUserMealsInDays(mealsInDays, userId, newProgramDocument.id as string, path, errorSavingDataMessageText)).then(() => {
                resolve({success: true});
              });
            } else {
              resolve(true);
            }
          });
      }
    } catch (e) {
      reject({
        success: false,
        errorMessage: errorSavingDataMessageText,
      });
    }
  });
};
export const processUserNutritionPlans = (nutritionPlans: UserNutritionPlan[], userId: string, mealsInDayDeletions: MealsInDayDeletion[],nutritionPlanDeletions: NutritionPlanDeletion[], path: 'users' | 'invitations', errorSavingDataMessageText: string) => {
  
  
  const updates = nutritionPlans.filter((p) => p.id !== undefined);
  const inserts = nutritionPlans.filter((p) => p.id === undefined);
  return [
    ...updates.map((u) => persistUserNutritionPlan(u, userId, "UPDATE", path, errorSavingDataMessageText)),
    ...inserts.map((u) => persistUserNutritionPlan(u, userId, "INSERT", path, errorSavingDataMessageText)),
    ...deleteNutritionPlanDocuments(userId, nutritionPlanDeletions, path),
    ...deleteMealsInDayDocuments(userId, mealsInDayDeletions, path),
  ];
};

const updateNutritionPlan = (nutrition_plans: UserNutritionPlan[], nutritionPlanDeletions: NutritionPlanDeletion[], userId: string, mealsInDayDeletions: MealsInDayDeletion[], errorSavingDataMessageText: string) => {

  return new Promise((resolve) => {
    if (nutrition_plans && nutrition_plans.length > 0 || nutritionPlanDeletions!== undefined) {            
          
      Promise.all(              
        processUserNutritionPlans(
          nutrition_plans,
          userId,
          mealsInDayDeletions,
          nutritionPlanDeletions,
          'users',
          errorSavingDataMessageText
        )
      ).then(() => {
        resolve({success: true});
      });
    } else{
      resolve({success: true});
    }
  })
};

const updateTrainers = (userId: string, newTrainers: string[], withoutTrainer: boolean) => {
  return new Promise(async (resolve) => {
    await firestore.collection("users").doc(userId).update(
      {
        trainers: newTrainers,
        withoutTrainer: withoutTrainer
      }
    ).then(() => {
      resolve(true);
    })
  })
}

const updatePrograms = (programs: UserProgram[], programDeletions: ProgramDeletion[], workoutDeletions: WorkoutDeletion[], userId: string, errorSavingDataMessageText: string) => {
  return new Promise((resolve) => {
    if ((programs && programs.length > 0)
    || (programDeletions && programDeletions.length > 0) || (workoutDeletions && workoutDeletions.length > 0)
    ) {
      Promise.all(
        processUserPrograms(
          programs,
          userId,
          workoutDeletions,
          programDeletions,
          'users',
          errorSavingDataMessageText
        )
      ).then(() => {
        resolve({success: true});
      });
    } else{
      resolve({success: true});
    }
  });
};

const updateWorkouts = (workouts: WorkoutDay[], userId: string, errorSavingDataMessageText: string) => {
  return new Promise((resolve) => {
    if(workouts && workouts.length > 0) {
      Promise.all(
        processUserWorkouts(
          workouts,
          userId,
          errorSavingDataMessageText
        )
      ).then(() => {
        resolve({success: true});
      });
    } else {
      resolve({success: true});
    }
  });
};


const updateTrainerNotifications = (trainerNotifications: Activity[], deletedTrainerNotifications: string[], errorSavingDataMessageText: string) => {
  return new Promise((resolve) => {
    if((trainerNotifications && trainerNotifications.length > 0) || (deletedTrainerNotifications && deletedTrainerNotifications.length > 0)) {
      Promise.all(
        processTrainerNotifications(
          trainerNotifications,
          deletedTrainerNotifications,
          errorSavingDataMessageText
        )
      ).then(() => {
        resolve({success: true});
      });
    } else {
      resolve({success: true});
    }
  });
};

const updateWithoutTrainerProp = async (organizationId: string, userId: string, isWithoutTrainer: boolean, errorSavingDataMessageText: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const ref = firestore.collection("organizationUser").doc(`${userId}-${organizationId}`);
      const doc = await ref.get();
      if (doc.exists) {

        await ref.update({
          withoutTrainer: isWithoutTrainer
        });
        resolve({ success: true });
      }
    } catch (e) {
      reject({
        success: false,
        errorMessage: errorSavingDataMessageText,
      });
    }
  });
};

export const processOrganization = async (organizationId: string, userId: string, withoutTrainer: boolean, errorSavingDataMessageText: string) => {
  await updateWithoutTrainerProp(organizationId, userId, withoutTrainer, errorSavingDataMessageText);
};

const updateOrganization = async (organizationId: string, userId: string, withoutTrainer: boolean, errorSavingDataMessageText: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      await processOrganization(organizationId, userId, withoutTrainer, errorSavingDataMessageText);
      resolve({ success: true });
    } catch (e) {
      reject({
        success: false,
        errorMessage: "An unexpected error occurred.",
      });
    }
  });
};


export const updateUser = (data: UserWithCRUDInstructions, errorSavingDataMessageText: string): Promise<APIResponse> => {
  const {trainers, programs, workouts, workoutDeletions, programDeletions, nutrition_plans, nutritionPlanDeletions, mealsInDayDeletions, trainerNotifications, deletedTrainerNotifications, withoutTrainer} = data;
  delete data.programs;
  delete data.workoutDeletions;
  delete data.programDeletions;
  delete data.nutrition_plans;
  delete data.mealsInDayDeletions;
  delete data.nutritionPlanDeletions;
  delete data.trainers;
  delete data.trainerNotifications;
  delete data.deletedTrainerNotifications;
  delete data.withoutTrainer;
  
  return new Promise(async (resolve, reject) => {
    try {
      if (data.id) {
        const trainersResult = await updateTrainers(data.id, trainers || [], withoutTrainer || false);
        const nutritionResult = await updateNutritionPlan(nutrition_plans || [], nutritionPlanDeletions || [], data.id, mealsInDayDeletions || [], errorSavingDataMessageText);
        const programsResult = await updatePrograms(programs || [], programDeletions || [], workoutDeletions || [], data.id, errorSavingDataMessageText);
        const workoutsResult = await updateWorkouts(workouts || [], data.id, errorSavingDataMessageText);
        const trainerNotificationsResult = await updateTrainerNotifications(trainerNotifications || [], deletedTrainerNotifications || [], errorSavingDataMessageText);
        const organizationResult = await updateOrganization(data.currentOrganization ?? "", data.id, withoutTrainer || false, errorSavingDataMessageText);
        Promise
          .all([trainersResult, nutritionResult, programsResult, workoutsResult, trainerNotificationsResult, organizationResult])
          .then(() => resolve({success: true}));
      }
    } catch (e) {
      reject({
        success: false,
        errorMessage: "There was an error saving your data.",
      });
    }
  });
};

type UpdateUserProfile = {
  userId: string;
  userData: User;
  updatePath: 'users';
}

type UpdateInvitationProfile = {
  userId: string;
  userData: UserInvitationWithCrudInstructions;
  updatePath: 'invitations';
}

export const updateUserOrInvitationProfileData = (data: UpdateUserProfile | UpdateInvitationProfile): Promise<void | any> => {
  delete data.userData.nutrition_plans;
  delete data.userData.programs;
  return firestore.collection(data.updatePath).doc(data.userId).set(data.userData, {merge: true});
};

export async function assignOrganization(userId: string, organizationId: string, role: Role) {
  try {
    const newOrganization = [{id: organizationId, role: role, archived: false}];
    await firestore.collection("users").doc(userId).update({
      organizations: firebase.firestore.FieldValue.arrayUnion(...newOrganization),
      currentOrganization: organizationId,
    });

    const newMember = role === Role.Admin || role === Role.SuperAdmin
      ? {admins: firebase.firestore.FieldValue.arrayUnion(userId)}
      : {trainers: firebase.firestore.FieldValue.arrayUnion(userId)};
    await firestore.collection("organizations").doc(organizationId).update(newMember);

  } catch (err) {
    throw err;
  }
}

const usersApi = (roles: Role[]) =>
  ({
    create: createUser,
    update: updateUser,
    list: listUsers(roles),
    get: getUser,
    getCorpUsers: getCorporateOrganizationUsers
  } as ApiInterface<UserWithCRUDInstructions>);

export default usersApi;

/**
 *
 * @param user - user object, it should have id set (in case of new users pull it from auth metadata)
 * @param invitation
 */
export const acceptInvitation = async (user: User, invitation: Invitation, errorSavingDataMessageText?: string) => {
  const shouldArchiveClient = await isOrganizationOnTheFreePlanAndHasTwoClients(invitation.organization, true, true) || invitation.archived;
  const ref = firestore.collection("users").doc(user.id);
  try {
    await ref.set(invitation.role === Role.Client ? {
      ...user,
      email: (user.email != "" && user.email)  || invitation.email,
      firstName: (user.firstName  != "" && user.firstName) || invitation.firstName,
      lastName: (user.lastName  != "" && user.lastName) || invitation.lastName,
      phoneNumber: user.phoneNumber || invitation.phoneNumber,
      fitnessProfile: user.fitnessProfile || invitation.fitnessProfile || null,
      trainers: firebase.firestore.FieldValue.arrayUnion(invitation.invitedBy),
      ...(shouldArchiveClient && {invitationArchived:true, invitationId: invitation.id })
    } : {
      ...user,
      email: user.email || invitation.email,
      firstName: user.firstName ||invitation.firstName,
      lastName: user.lastName || invitation.lastName,
      phoneNumber: user.phoneNumber || invitation.phoneNumber,
      fitnessProfile: user.fitnessProfile || invitation.fitnessProfile || null,
    }, {merge: true});

    invitation.role !== Role.SuperAdmin
      ? await assignOrganization(user.id!, invitation.organization, invitation.role)
      : await ref.update({isSuperAdmin: true});


    if (invitation.programs) {
      invitation.programs.map((program)=>{
        delete program.id;
      })
      await processUserPrograms(invitation.programs, user.id!, [], [], 'users', errorSavingDataMessageText);
    }
    await invitationsApi.acceptInvitation(invitation.id!);

    return true;
  } catch (err) {
    return false;
  }
};

export const getCurrentUserRole = (user: User | null, organizationOverrideId?: string): Role | undefined => {
  if(!user){
    return undefined;
  }
  if (organizationOverrideId) {
    return Role.Admin;
  }

  //@ts-ignore
  if (user.role === Role.SuperAdmin || user.isSuperAdmin) {
    return Role.SuperAdmin;
  }
  // this code here is a migration code and will be removed when users and org associations are migrated to new structure
  // @ts-ignore
  const associatedOrg = user.organizations
    ? user.organizations
      .map((item) => {
        // this is temporal until we migrate to new structure
        //@ts-ignore
        if (typeof item === "string") {
          return {id: item, role: Role.Admin};
        } else {
          return item;
        }
      })
      .find((item) => {
        return item.id === user.currentOrganization;
      })
    : undefined;
  if (associatedOrg) {
    return associatedOrg.role;
  } else {
    return undefined;
  }
};

//clients with no org do not have roles
export const isUserClient = (r : Role | undefined) => !r || Role.Client === r

export const getUserByEmail = (
  email: string,
  wlOrgId:string,
): Promise<User | null> =>
  new Promise((resolve, reject) => {
    return firestore.collection('users').where('email', '==', email).where('whitelabel_org_id', '==', wlOrgId).get().then((snapshot) => {
      snapshot.empty ? resolve(null) : resolve({id: snapshot.docs[0].id, ...snapshot.docs[0].data()} as User);
    }).catch((err) => {
      reject(null);
    })
  });

  export const handleMakeCopyOfWorkoutForOtherUser = async(assignedWorkout:WorkoutDay, userId:string) =>{
    const programId = await getIdForSingleWorkoutsProgram(userId, 'users');
    assignedWorkout = {
      ...assignedWorkout,
      finished:false,
      userId:userId,
      isSingleWorkout: true,
      firstInCycle:false,
      parentId: programId,
      isNotSaved:true
      
    }
    delete assignedWorkout?.id;
    delete assignedWorkout.generatedBy;    
    
    return assignedWorkout;
  }

  export const copyUserNutritionPlanToOtherUser = async(assignedNutritionPlan:UserNutritionPlan, userId:string, invitation:boolean, errorSavingDataMessageText: string)  =>{
    return new Promise(async (resolve, reject) => {
      try {
        const ref = firestore.collection(`/${invitation?"invitations":"users"}/${userId}/assignedNutritionPlans`);
        ref.add({...sanitizeInput(assignedNutritionPlan)}).then((newProgramDocument) => {
              if (assignedNutritionPlan.meals && assignedNutritionPlan.meals.length > 0) {
                Promise.all(processUserMealsInDays(assignedNutritionPlan.meals, userId, newProgramDocument.id as string, invitation?"invitations":"users", errorSavingDataMessageText)).then(() => {
                  resolve({success: true});
                });
              } else {
                resolve(true);
              }
            });          
      } catch (e) {
        reject({
          success: false,
          errorMessage: errorSavingDataMessageText,
        });
      }
    });   
  }


  export const getUserClientsWithinAnOrganization = async(orgid:string, trainerId:string, isOrgAdmin:boolean):Promise<any> => {
    const result = await firestore
      .collection("organizationUser")
      .where("orgId", "==", orgid)
      .where("role", "==", "Client")
      .where("archived","==", false)
      .get()
      .then((snap)=>{
        return snap.docs.filter(u => {
          const user = u.data()
          return isOrgAdmin || user?.user?.trainers?.includes(trainerId || '')
        });
      })
      return result;
  }

export const isEmailOrPhoneNumberInTheDatabase = (email_input:string, organizationId: string, wlOrgId:string):Promise<any>  => new Promise(async(resolve, reject) =>{
  const checkIfValidInput = validateEmail(email_input);
  if(typeof checkIfValidInput === "string"){return resolve(checkIfValidInput)}
  let rValue : number = 0;
  //check if  email exists in either invitations or users collection
  //check users collection, registered email is set within email field of an account
  await firestore.collection('users').where("email", '==', email_input.trim()).where("whitelabel_org_id", "==", wlOrgId).get().then((snap) => {
    if(snap.docs[0]){
      if(snap.docs[0].data().currentOrganization){
        if(snap.docs[0].data().currentOrganization === organizationId){
          if(snap.docs[0].data().withoutTrainer === true){
            rValue = 4;
          } else {
            rValue = 2;
          }
        }
        else{
          rValue = 1;
        }
      }
      else{
        rValue = 4;
      }
    }
  })

  //check invitations
  rValue !== null && await firestore.collection('invitations').where("email", '==', email_input.trim()).where("whitelabel_org_id", "==", wlOrgId).get().then((snap) => {
    snap.docs.forEach(
      s => {
        if(s.data().acceptedOn === null){
          if(s.data().organization === organizationId){
            rValue = 3;
          }
          else{
            rValue = 1;
          }
        }
      }
    )
  })

  return resolve(rValue);
});

export const isSuperKartica  = (loyaltyNumber:number|null):Promise<any>  => new Promise(async(resolve, reject) =>{
  const checkIfValidInput = checkSuperCardNum(loyaltyNumber);
  if(typeof checkIfValidInput === "string"){return resolve(checkIfValidInput)}
  if(checkIfValidInput === false){return resolve("superkartica.your_card_number_is_invalid_please_try_again")}
  let rValue : boolean | null = checkIfValidInput;

  return resolve(rValue);
});

export const isSuperKarticaInDB = (loyaltyNumber:number|null):Promise<any>  => new Promise(async(resolve, reject) =>{
let rValue = true;
//check if card # exists in users collection
  await firestore.collection('users').where("loyaltyPrograms.superKartica.cardNumber", '==', loyaltyNumber).get().then((snap) => {
  if(snap.docs[0]){
    if(snap.docs[0].data()){rValue = false;}
  }
})
return resolve(rValue);
});


export const getUserByUserId = async(userId: string):Promise<any> => {
  const result = await firestore
    .doc(`/users/${userId}`)
    .get()
    .then((doc)=>{
      return doc.data();
    })
    return result;
}
