import {useContext, useEffect, useState} from 'react';
import {getOrganizationBillingDetails} from "../api/billingDetailsApi";
import {BillingDetails, Role} from "myfitworld-model";
import {getCurrentUserRole} from "../api/usersApi";
import UserContext from "../contexts/UserContext";
import { useIntl } from 'react-intl';
import hookMessages from './messages';

const useBillingDetails = (userOrganization?: string) => {
  const userContext = useContext(UserContext);
  const {formatMessage} = useIntl();
  
  const [billingDetails, setBillingDetails] = useState<BillingDetails | undefined>(undefined);
  const [rebrandButtonLabel, setRebrandButtonLabel] = useState(formatMessage(hookMessages.enable_rebrand));
  
  useEffect(() => {
    if (userOrganization) {
      getOrganizationBillingDetails(userOrganization)
        .then((res) => {
          if (res) {
            setBillingDetails(res);
            if(userContext.user && getCurrentUserRole(userContext.user) === Role.Admin && res?.rebrandSubscription?.subscriptionStatus === "active" ||
                res?.rebrandSubscription?.subscriptionStatus === "trialing") {
                  setRebrandButtonLabel(formatMessage(hookMessages.disable_rebrand));
              } else {
                setRebrandButtonLabel(formatMessage(hookMessages.enable_rebrand));
              }
          }
        })
        .catch((error) => {
          console.error('Error fetching billing details:', error);
        });
    }
  }, [userOrganization]);

  return { billingDetails, rebrandButtonLabel };
};

export default useBillingDetails;
